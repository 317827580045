import React, { useEffect, useState } from "react";
import { string, func, bool, any } from "prop-types";
import { Input, Select, Form } from "antd";
import { labelStyles, stepStyles, errorStyles } from "./form";
import {
  queryEmployeeCheckedInfo,
  setUserReadInfo,
} from "../../utils/firebase/employees";

const { Option } = Select;

const locationsNumbers = ["1-5", "5-10", "10+"];
// const locationsNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10+"];

const Step2 = ({
  loading,
  setLoading,
  prospectFirstName,
  setProspectFirstName,
  prospectLastName,
  setProspectLastName,
  prospectEmail,
  setProspectEmail,
  prospectPhone,
  setProspectPhone,
  prospectCompanyName,
  setProspectCompanyName,
  prospectZip,
  setProspectZip,
  prospectLocationsNumber,
  setProspectLocationsNumber,
  errors,
  showErrors,
}) => {
  if (loading) {
    return (
      <div className="">
        <div className="text-center mx-auto w-full">Please wait...</div>
      </div>
    );
  }

  return (
    <div className={stepStyles}>
      <div>
        <div className={labelStyles}>Prospect First Name</div>
        <Input
          value={prospectFirstName}
          onChange={({ target: { value } }) => setProspectFirstName(value)}
          placeholder="E.g. John"
          size="large"
          style={{
            borderColor: showErrors && errors.firstName ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.firstName ? 100 : 0
          }`}
        >
          Please enter first name
        </div>
      </div>

      <div>
        <div className={labelStyles}>Prospect Last Name</div>
        <Input
          value={prospectLastName}
          onChange={({ target: { value } }) => setProspectLastName(value)}
          placeholder="E.g. Doe"
          size="large"
          style={{
            borderColor: showErrors && errors.lastName ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.lastName ? 100 : 0
          }`}
        >
          Please enter last name
        </div>
      </div>

      <div>
        <div className={labelStyles}>Prospect Email</div>
        <Input
          value={prospectEmail}
          onChange={({ target: { value } }) => setProspectEmail(value)}
          placeholder="E.g. johndoe@mail.com"
          size="large"
          style={{
            borderColor: showErrors && errors.email ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.email ? 100 : 0
          }`}
        >
          Please enter a valid email
        </div>
      </div>

      <div>
        <div className={labelStyles}>Prospect Phone Number</div>
        <Input
          value={prospectPhone}
          onChange={({ target: { value } }) => setProspectPhone(value)}
          placeholder="E.g. 111 222 3333"
          size="large"
          style={{
            borderColor: showErrors && errors.phone ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.phone ? 100 : 0
          }`}
        >
          Please enter a valid phone
        </div>
      </div>

      <div>
        <div className={labelStyles}>Name of Business</div>
        <Input
          value={prospectCompanyName}
          onChange={({ target: { value } }) => setProspectCompanyName(value)}
          placeholder="E.g. John's Wing Shack"
          size="large"
          style={{
            borderColor: showErrors && errors.companyName ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.companyName ? 100 : 0
          }`}
        >
          Please enter the business name
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-0 md:gap-y-6">
        <div className="w-full">
          <div className={labelStyles}>Zip Code</div>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter a zip code",
              },
            ]}
            validateStatus={showErrors && errors.zip ? "error" : ""}
          >
            <Input
              value={prospectZip}
              onChange={({ target: { value } }) => setProspectZip(value)}
              placeholder="E.g. 12345"
              size="large"
              style={{
                borderColor: showErrors && errors.zip ? "#F73E3C" : "",
              }}
            />
            <div
              className={`${errorStyles} opacity-${
                showErrors && errors.zip ? 100 : 0
              }`}
            >
              Please enter a valid zip code
            </div>
          </Form.Item>
        </div>

        <div className="w-full">
          <div className={labelStyles}># of locations</div>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select a state",
              },
            ]}
            validateStatus={showErrors && errors.locations ? "error" : ""}
          >
            <Select
              className="w-full"
              defaultValue={prospectLocationsNumber}
              onChange={(value) => setProspectLocationsNumber(value)}
              style={{
                borderColor: showErrors && errors.locations ? "#F73E3C" : "",
              }}
            >
              <Option value="" />
              {locationsNumbers.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
            <div
              className={`${errorStyles} opacity-${
                showErrors && errors.locations ? 100 : 0
              }`}
            >
              Please select # of locations
            </div>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

Step2.propTypes = {
  loading: bool.isRequired,
  setLoading: func.isRequired,
  prospectFirstName: string.isRequired,
  setProspectFirstName: func.isRequired,
  prospectLastName: string.isRequired,
  setProspectLastName: func.isRequired,
  prospectEmail: string.isRequired,
  setProspectEmail: func.isRequired,
  prospectPhone: string.isRequired,
  setProspectPhone: func.isRequired,
  prospectCompanyName: string.isRequired,
  setProspectCompanyName: func.isRequired,
  prospectZip: string.isRequired,
  setProspectZip: func.isRequired,
  prospectLocationsNumber: string.isRequired,
  setProspectLocationsNumber: func.isRequired,
  showErrors: bool.isRequired,
  errors: any.isRequired,
};

export default Step2;
