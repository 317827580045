import React, { useState } from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import AllyAffiliateForm from "../../../page-components/ally-affiliates/form";
import bg from "../../../images/svg/vector-14.svg";
import ogImage from "../../../images/global_assets/og-image.png";

const AllyAffiliatePage = () => {
  const [step, setStep] = useState(1);

  return (
    <Layout
      transparentHeader
      isLanding
      useNewLPHeader
      className="non-fixed-width"
    >
      <SEO
        noIndex
        title="SpotOn Partner Program"
        image={`https://spoton.com/${ogImage}`}
      />

      <img src={bg} alt="background" className="absolute top-0 right-0 " />

      <AllyAffiliateForm step={step} setStep={setStep} />
    </Layout>
  );
};

export default AllyAffiliatePage;
