export const title = "Welcome to the SpotOn Partner Program";

export const subTitle = `Know a business that could benefit from joining the SpotOn family? Fill out the form below to start the referral process.`;

export const stepTitles = {
  step1: "Your Information",
  step2: "Referral Information",
  step3: "Tell us about your referral",
};

export const campaignInfo = {
  title: "How the employee referral program works",
  bullets: [
    `Once you enter the referral it will be routed to our referral team to determine if the lead is eligible. We will check to see if any existing record exists. If it does exist and an activity has been logged in the last 90 days, the referral does not qualify. (this is based on current rules of engagement set for A/E’s).`,
    `You will receive an email stating your referral is either not eligible or is eligible and ready for an intro to the assigned Account Executive.`,
    `A soft email introduction is required. You can use one of our templates or draft something custom to introduce the assigned A/E to your referral contact.`,
    `Your job is done! Once the referral is closed you will receive an email letting you know, and the referral bonus will reflect in the end of quarter paycheck.`,
    `For any additional questions referral related you can email the team at <a href="mailto:referral@spoton.com" class="text-primary">referral@spoton.com</a>`,
  ],
};
