import React from "react";
import { string, func, bool } from "prop-types";
import { Input } from "antd";
import { labelStyles, errorStyles } from "./form";

const Step1 = ({ allyId, setAllyId, showErrors }) => {
  return (
    <div className="max-w-[575px] mx-auto">
      <div>
        <div className={labelStyles}>Your SpotOn Partner ID:</div>
        <Input
          value={allyId}
          onChange={({ target: { value } }) => setAllyId(value)}
          placeholder="E.g. xaxbcaJk"
          size="large"
          style={{
            borderColor: showErrors ? "#F73E3C" : "",
          }}
        />
        <div className={`${errorStyles} opacity-${showErrors ? 100 : 0}`}>
          <p>Please provide a valid SpotOn Partner Id</p>
          <p>
            You can find your Partner ID in the progam onboarding welcome
            e-mail.
          </p>
        </div>
      </div>
    </div>
  );
};

Step1.propTypes = {
  allyId: string.isRequired,
  setAllyId: func.isRequired,
  showErrors: bool.isRequired,
};

export default Step1;
