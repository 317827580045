/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from "react";
import { func, number } from "prop-types";
import queryString from "query-string";
import { isMobile } from "react-device-detect";
import { navigate } from "gatsby";
import Cookies from "js-cookie";
import Heading from "../../components-v2/Base/HeadingBuilder";
import { stepTitles, subTitle, title } from "../../data/ally";
import CtaSecondary from "../../components-v2/Base/CtaSecondary";
import CtaPrimary from "../../components-v2/Base/CtaPrimary";
import Step1 from "./step-1";
import Step2 from "./step-2";
import Step3 from "./step-3";
import {
  emailValidationRegExp,
  phoneValidationRegExp,
  zipValidationRegExp,
} from "../../constants";
import { submitALead, getAlly } from "../../utils/firebase/ally-partners";

const ALLY_ID = "ally_id";

const AllyAffiliateForm = ({ step, setStep }) => {
  const [allyId, setAllyId] = useState("");
  const [allyData, setAllyData] = useState(null);

  const [prospectFirstName, setProspectFirstName] = useState("");
  const [prospectLastName, setProspectLastName] = useState("");
  const [prospectEmail, setProspectEmail] = useState("");
  const [prospectPhone, setProspectPhone] = useState("");
  const [prospectCompanyName, setProspectCompanyName] = useState("");
  const [prospectZip, setProspectZip] = useState("");
  const [prospectLocationsNumber, setProspectLocationsNumber] = useState("");

  const [additionalInfo, setAdditionalInfo] = useState("");

  const [hasAllyId, setHasAllyId] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const [showSubmitError, setShowSubmitError] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [step2Errors, setStep2Errors] = useState({
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    companyName: true,
    zip: true,
    locations: true,
  });

  const [step3Errors, setStep3Errors] = useState({
    additionalInfo: true,
  });

  const [step2loading, setStep2Loading] = useState(true);

  const getUser = async () => {
    const user = await getAlly(allyId.trim());
    setAllyData(user);
  };

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const urlId = parsed[ALLY_ID];
    const cookieId = Cookies.get(ALLY_ID);

    if (urlId) {
      setAllyId(urlId);
      Cookies.set(ALLY_ID, urlId);
      setStep2Loading(false);
      setStep(2);
      setDataLoading(false);
      setHasAllyId(true);
      return;
    }

    if (cookieId) {
      setAllyId(cookieId);
      setStep(2);
      setStep2Loading(false);
      setDataLoading(false);
      setHasAllyId(true);
      return;
    }

    setDataLoading(false);
    setHasAllyId(false);
  }, []);

  useEffect(() => {
    getUser();
  }, [allyId]);

  const onSubmit = async () => {
    setLoading(true);
    setShowSubmitError(false);

    // const now = new Date();
    // const submittedOn = `${
    //   now.getMonth() + 1
    // }-${now.getDate()}-${now.getFullYear()}`;
    // const address = zipCodesMap.get(prospectZip);
    const formData = {
      ally_id: allyId,
      allyFirstName: allyData?.firstName,
      allyLastName: allyData?.lastName,
      allyEmail: allyData?.email,
      allyPhone: allyData?.phone,
      allyCompany: allyData?.company,
      utmSource: allyData?.wantToBe ?? "Ally Partner (Affiliate)",
      utmCampaign: Cookies.get("utmCampaign") || "",
      prospectFirstName,
      prospectLastName,
      prospectEmail,
      prospectPhone,
      prospectCompanyName,
      prospectZip,
      // prospectCity: address.city,
      // prospectState: address.state,
      prospectLocationsNumber,
      additionalInfo,
      // submittedOn,
    };

    const submitted = await submitALead(allyId, formData);
    setLoading(false);

    if (submitted) {
      navigate("/partners/ally/thank-you");
    } else {
      setShowSubmitError(true);
    }
  };

  useEffect(() => {
    if (isMobile) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [step]);

  useEffect(() => {
    if (step === 2) {
      const hasErrors = Object.values(step2Errors).includes(true);
      if (!hasErrors) {
        setStep(3);
        setShowErrors(false);
      }
    }

    if (step === 3) {
      const hasErrors = Object.values(step3Errors).includes(true);
      if (!hasErrors) {
        setShowErrors(false);
        if (!loading) {
          onSubmit();
        }
      }
    }
  }, [step2Errors, step3Errors]);

  const checkStep2Errors = () => {
    setStep2Errors({
      firstName: !prospectFirstName.trim().length > 0,
      lastName: !prospectLastName.trim().length > 0,
      email: !emailValidationRegExp.test(prospectEmail),
      phone: !phoneValidationRegExp.test(prospectPhone),
      companyName: !prospectCompanyName.trim().length > 0,
      zip:
        !prospectZip.trim().length > 0 ||
        !zipValidationRegExp.test(prospectZip),
      locations: !prospectLocationsNumber.trim().length > 0,
    });
  };

  const checkStep3Errors = () => {
    setStep3Errors({
      additionalInfo: !additionalInfo.trim().length > 0,
    });
  };

  const onNextClick = async () => {
    if (step === 1) {
      await getUser();

      if (allyId && !!allyData) {
        setStep2Loading(false);
        setShowErrors(false);
        return setStep(2);
      }
      setShowErrors(true);
    }
    if (step === 2) {
      checkStep2Errors();
      setShowErrors(true);
    }
    if (step === 3) {
      checkStep3Errors();
      setShowErrors(true);
    }

    return true;
  };

  const onBackClick = () => {
    setStep((st) => st - 1);
  };

  const stepBaseStyles =
    "text-[18px] w-[32px] h-[32px] rounded-full flex items-center justify-center mx-3 border border-solid";
  const stepCompleteStyles = "text-white bg-primary border-primary";
  const stepIncompleteStyles = "text-black-400 border-black-400";

  const step1Props = {
    allyId,
    setAllyId,
    showErrors,
  };

  const step2Props = {
    loading: step2loading,
    setLoading: setStep2Loading,
    prospectFirstName,
    setProspectFirstName,
    prospectLastName,
    setProspectLastName,
    prospectEmail,
    setProspectEmail,
    prospectPhone,
    setProspectPhone,
    prospectCompanyName,
    setProspectCompanyName,
    prospectZip,
    setProspectZip,
    prospectLocationsNumber,
    setProspectLocationsNumber,
    showErrors,
    errors: step2Errors,
  };

  const step3Props = {
    additionalInfo,
    setAdditionalInfo,
    showErrors,
    errors: step3Errors,
  };

  const stepsMap = {
    1: <Step1 {...step1Props} />,
    2: <Step2 {...step2Props} />,
    3: <Step3 {...step3Props} />,
  };

  const stepToTitleMap = {
    1: stepTitles.step1,
    2: stepTitles.step2,
    3: stepTitles.step3,
  };

  if (dataLoading) {
    return (
      <section
        className={`
      bg-black w-full mx-auto pt-40
      pb-64 md:pb-48 lg:pb-48 2xl:pb-80
      mb-20
      min-h-[70vh]
      `}
      />
    );
  }

  return (
    <>
      <section
        className={`
      bg-black w-full mx-auto pt-40
      pb-64 md:pb-48 lg:pb-48 2xl:pb-80
      mb-20
      `}
      >
        <div className="max-w-1440 w-full mx-auto px-4 lg:px-10">
          <Heading
            level={1}
            injectionType={isMobile ? 2 : 1}
            withBlueSymbol
            className="text-white text-center relative"
          >
            {title}
          </Heading>
          <p className="relative mx-auto text-center text-black-500 text-p max-w-[630px]">
            {subTitle}
          </p>

          <div className="flex flex-row justify-center my-8 md:my-10">
            {!hasAllyId && (
              <>
                <div className={`${stepBaseStyles} ${stepCompleteStyles}`}>
                  1
                </div>

                <span
                  className="mb-[16px] w-[70px] md:w-[100px]"
                  style={{ borderBottom: "1px dashed #8A95A6" }}
                />
              </>
            )}

            <div
              className={`${stepBaseStyles} ${
                step > 1 ? stepCompleteStyles : stepIncompleteStyles
              }`}
            >
              {hasAllyId ? 1 : 2}
            </div>

            <span
              className="mb-[16px] w-[70px] md:w-[100px]"
              style={{ borderBottom: "1px dashed #8A95A6" }}
            />

            <div
              className={`${stepBaseStyles} ${
                step === 3 ? stepCompleteStyles : stepIncompleteStyles
              }`}
            >
              {hasAllyId ? 2 : 3}
            </div>
          </div>
        </div>

        <div
          style={{
            perspective: "3000px",
          }}
          className="relative"
        >
          <section className="mt-10 md:mt-20 transition transition-all duration-300">
            <div
              className={`
            bg-white rounded-lg
            mx-4 lg:mx-40 2xl:mx-64
            px-6 lg:px-10 2xl:px-24
            py-6 lg:py-10
          `}
              style={{
                boxShadow: "0px 32px 60px rgba(0, 0, 0, 0.07)",
                margin: "auto",
                maxWidth: "1000px",
              }}
            >
              <Heading
                level={3}
                injectionType={3}
                className="text-black text-center"
                withBlueSymbol
              >
                {stepToTitleMap[step]}
              </Heading>

              {stepsMap[step]}

              <div className="flex flex-col-reverse md:flex-row items-center gap-x-8 justify-center mt-4 md:mt-10">
                {step > 1 && (
                  <CtaSecondary
                    className="w-full md:w-auto justify-center my-4 md:my-auto"
                    title="Go back"
                    reverseIcon
                    large
                    onClick={onBackClick}
                  />
                )}
                {step <= 3 && (
                  <CtaPrimary
                    className="w-full md:w-auto justify-center my-4 md:my-auto"
                    title={step < 3 ? "Next step" : "Submit"}
                    onClick={onNextClick}
                    disabled={loading}
                  />
                )}
              </div>
              {!!showSubmitError && (
                <div className="text-[12px] text-danger text-center mt-2">{`We couldn't process the form. Please try again later.`}</div>
              )}
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

AllyAffiliateForm.propTypes = {
  step: number.isRequired,
  setStep: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};

export default AllyAffiliateForm;

export const labelStyles = "text-black-100 mb-1 font-[15px]";
export const stepStyles = `
      relative grid grid-cols-1 md:grid-cols-2
      gap-x-8
      gap-y-2
    `;
export const errorStyles = "text-danger text-[12px] mt-1";
